<template>
  <div class="app-container">
	<el-select v-model="value" @change="selC" placeholder="请选择">
		<el-option
		  v-for="item in options"
		  :key="item.id"
		  :label="item.coal"
		  :value="item.id">
		</el-option>
	</el-select>
    <el-container>
      <div id="chart_one" style="width: 100%; height: 90vh"></div>
    </el-container>
  </div>

</template>

<script>
import * as echarts from "echarts";
// import {getZzt,getZzt_1} from "@/api/cbfx/sjfx";
export default {
  data() {
    return {
		options:[],
		value:''
    };
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() { 
    
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {
	this.getRequest('/dataList/getAllCoal/').then(resp=>{
	  if (resp.data){ 
	    console.log(resp.data)
		this.options=resp.data;
		this.value=resp.data[0].id;
		this.getList(this.value); 
	  }
	});
  },
  // methods方法
  methods: {
	selC(e){
		console.log(e);
		this.getList(e);
	},
    init() {
      this.$nextTick(() => {
        this.getList();
      });
    },
    getList(id) {  
		console.log(id)
		this.getRequest('/coalManage/getFiveLevel?coalId='+id).then(resp=>{
		  if (resp.data){ 
		    console.log(resp.data) 
			let data=[resp.data];
			var myChart1 = echarts.getInstanceByDom(document.getElementById("chart_one"));
			if (myChart1 == undefined) {
			    myChart1 = echarts.init(document.getElementById("chart_one"));
			} 
			const themeColor = "#5555ff";
			const fontColor = "#fffbff";
			const childrenColor = "#39fdc0";
			
			let dser=[];
			data.forEach(function(item,index){ 
				dser.push({
					name: item.name,
					value: index,
					symbolSize: 70,
					color: "#f40",
					itemStyle: {
						borderColor: themeColor,
						color: themeColor,
					},
					label: {
						position: "inside",
					},
					children: [],
				})
				item.children.forEach(function(item_1,index_1){
					dser[index].children.push({
						name: item_1.name,
						value: index_1,
						symbolSize: 60,
						color: "#f40",
						itemStyle: {
							borderColor: '#1e1395',
							color: '#1e1395',
						},
						label: {
							position: "inside",
						},
						children: [],
					})
					if(item_1.children!=undefined){
						item_1.children.forEach(function(item_2,index_2){
							dser[index].children[index_1].children.push({
								name: item_2.name,
								value: index_2,
								symbolSize: 60,
								color: "#f40",
								itemStyle: {
									borderColor: '#55ff7f',
									color: '#55ff7f',
								},
								label: {
									position: "inside",
								},
								children: [],
							})
							
							if(item_2.children!=undefined){  
								item_2.children.forEach(function(item_3,index_3){
									dser[index].children[index_1].children[index_2].children.push({
										name: item_3.name,
										value: index_3,
										symbolSize: 60,
										color: "#f40",
										itemStyle: {
											borderColor: '#39fdc0',
											color: '#39fdc0',
										},
										label: {
											position: "inside",
										},
										children: [],
									}) 
									if(item_3.children!=undefined){  
										item_3.children.forEach(function(item_4,index_4){
											dser[index].children[index_1].children[index_2].children[index_3].children.push({
												name: item_4.name,
												value: index_4,
												symbolSize: 60,
												color: "#f40",
												itemStyle: {
													borderColor: '#ff0000',
													color: '#ff0000',
												},
												label: {
													position: "inside",
												},
												children: [],
											})  
											if(item_4.children!=undefined){
												item_4.children.forEach(function(item_5,index_5){
													dser[index].children[index_1].children[index_2].children[index_3].children[index_4].children.push({
														name: item_5.name,
														value: index_5,
														symbolSize: 60,
														color: "#00ffff",
														itemStyle: {
															borderColor: '#00ffff',
															color: '#00ffff',
														},
														label: {
															position: "inside",
														}, 
													})  
												})
											}
										})
									}
								})
							}
						})
					}
					
				})
			})
			var option = {
			   toolbox: {
			       show: true,
			       feature: {
			         borderColor: fontColor,
			       },
			       feature: {
			         restore: {},
			       },
			     },
			   series: [
				   {
					   type: "tree",
						 layout: "radial", // 径向布局
						 symbol: "circle", // 设置样式
						 hoverAnimation: true, // hover样式
						 animationDurationUpdate: 1000, // 数据更新动画时长
						 expandAndCollapse: true, // 子树折叠和展开的交互
						 roam: true, // 开始鼠标缩放和平移漫游
						 symbolSize: 10,
						 label: {
						   color: fontColor,
						   rotate: 0,
						 },
						 data: dser,
				   }
			   ]
			};
			myChart1.setOption(option);  
		  }
		});
         
    },
  },
};
</script>
<style scoped lang="scss">
[v-cloak] {
	display: none;
}
</style>
